import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";

import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";
import Layout from "../../components/layout";

const Julia = () => {
  return (
    <Layout>
      <Head title="Julia Vinograd" />
      <h3>Julia Vinograd</h3>
      <p>1943 - 2018</p>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        Berkeley, CA
      </h4>
      <p>
        <OutboundLink href="https://en.wikipedia.org/wiki/Julia_Vinograd">
          Wikipedia
        </OutboundLink>
      </p>
      <p>
        <OutboundLink href="http://www.bigbridge.org/BD-JV-P.HTM">
          Website
        </OutboundLink>
      </p>

      <hr />

      <h3>LISTENING TO THE RADIO</h3>

      <pre>
        I am listening to the radio. I am not listening to the radio. I am
        listening to the silence in my room behind the radio. I am the radio.
        Listen. I can hear the night sucking its burnt fingers that touched the
        quarreling lovers. I can hear the big trucks going out, the white line
        whipping at their windshields. I can hear the old women selling terrible
        roses in the chlorine-lit subway. I can hear the young hustlers, their
        tight jeans glowing in the greedy dark. I can hear the ghosts mowing
        their own graves. It's very late. Everyone else is asleep with
        commercials pulled over their heads dreaming of sex and cigarettes and
        money and work. No, I don't know what they're dreaming, I don't even
        know if there's anyone else left. The radio talks to itself like a bag
        lady in an empty room. Not to me. I fell asleep an hour ago and didn't
        notice. I am the radio. I am the bag lady. I am the night. Listen.
      </pre>

      <h3>GINSBERG</h3>
      <pre>
        No blame. Anyone who wrote Howl and Kaddish earned the right to make any
        possible mistake for the rest of his life. I just wish I hadn't made
        this mistake with him. It was during the Vietnam war and he was giving a
        great protest reading in Washington Square Park and nobody wanted to
        leave. So Ginsberg got the idea, "I'm going to shout 'the war is over'
        as loud as I can," he said "and all of you run over the city in
        different directions yelling the war is over, shout it in offices,
        shops, everywhere and when enough people believe the war is over why,
        not even the politicians will be able to keep it going." I thought it
        was a great idea at the time, a truly poetic idea. So when Ginsberg
        yelled I ran down the street and leaned in the doorway of the sort of
        respectable down on its luck cafeteria where librarians and minor clerks
        have lunch and I yelled "the war is over". And a little old lady looked
        up from her cottage cheese and fruit salad. She was so ordinary she
        would have been invisible except for the terrible light filling her face
        as she whispered "My son. My son is coming home." I got myself out of
        there and was sick in some bushes. That was the first time I believed
        there was a war.
      </pre>

      <h3>FOR THE TOURISTS IN THE 60s</h3>
      <pre>
        I remember how the tourists saw us. They were wistful middle aged men
        who were about to meet a barefoot girl in an orange mini-skirt who'd
        give them a flower and take them to her pad and after one toke on a
        joint they'd be drugged and helpless and make love non-stop on a
        mattress on the floor and in the morning they'd wake up a communist. You
        could tell they were worried about it and even more worried that for
        some reason it hadn't happened yet. They believed in us more than they
        believed in the stockmarket. Even when they heard scary rumors they went
        right on trusting. I remember when I was hitchhiking this couple slowed
        down, looked me over, and then to be sure, asked me cautiously, "Are you
        a psychopath?" Of course, I'd tell them if I were. Of course. And the
        newspapers wrote furious articles about how naïve and gullible we were.
        I remember the tourists, clutching their cameras like teddybears,
        clicking their loneliness at us, getting everything wrong and waiting
        for magic. Sometimes I remember our magic just by thinking of their
        puzzled faces.
      </pre>
    </Layout>
  );
};

export default Julia;
